<template>
  <worker title="在职员工信息管理" isDiscipline="no"></worker>
</template>

<script>
import Worker from './worker'

export default {
  components: { Worker }
}
</script>
